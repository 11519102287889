import AutoRow from 'components/misc/AutoRow'
import { getFlag } from 'misc/flags'
import React, { useState, Fragment } from 'react'
import { Divider, Button, Icon, LoadingDots } from '@advanza/ui'
import Modal from '@advanza/modal'
import { Row, Col } from '@advanza/grid'
import SearchKvkCompanyInput from 'components/providers/SearchKvkCompanyInput'
import { InputField, Radio, SelectCheckbox } from '@advanza/input'
import { useForm } from 'hooks/miscHooks'
import GeoAutoComplete from '../GeoAutoComplete'
import { call } from '@advanza/api'
import { useDispatch, useSelector } from 'react-redux'
import { Select } from '@advanza/advanza_generic'
import CheckButton from 'components/CheckButton'
import A from 'components/ui/A'

const AddProForm = ({ refresh }) => {
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const [formOpen, setFormOpen] = useState(false)
    const [country, setCountry] = useState(useSelector((state) => state.countries.countries[0]))
    const [franchiseStatus, setFranchiseStatus] = useState(0)
    const [loading, setLoading] = useState(false)
    const [result, setResult] = useState(false)
    const { entities } = useSelector((state) => state['services'])
    const { services } = entities

    const visibleServices = Object.keys(services).filter(
        (key) => services[key].country_code === country
    )

    const fields = [
        { name: 'Rubriek', required: true, kvk_field: 'service_id' },
        { name: 'Naam', required: true, kvk_field: 'business_name', gmb_field: 'name' },
        { name: 'Plaats', required: true, kvk_field: 'city' },
        { name: 'Postcode', required: true, kvk_field: 'postcode', gmb_field: 'postal_code' },
        { name: 'Kvk nummer', required: true, kvk_field: 'kvk_nr' },
        { name: 'Vestiging ID', required: false },
        { name: 'Straat', required: true, kvk_field: 'street', gmb_field: 'route' },
        { name: 'Huisnummer', required: true, kvk_field: 'house_nr', gmb_field: 'street_number' },
        { name: 'Toevoeging', required: false, kvk_field: 'house_nr_add' },
        { name: 'Medewerkers', required: false, kvk_field: 'employees' },
        { name: 'Oprichting', required: false, kvk_field: 'established_date' },
        { name: 'Website', required: true, kvk_field: 'url', gmb_field: 'website' },
        { name: 'Email', required: true, kvk_field: 'email' },
        { name: 'Telefoon', required: false, gmb_field: 'formatted_phone_number' },
        { name: 'Voornaam', required: false },
        { name: 'Achternaam', required: false },
        { name: 'GMB', required: true },
    ]

    const { values, setValue, setValues, isValid, errors, validate, setValidations } = useForm()

    const initForm = (company = []) => {
        let _values = {}
        let _validations = {}
        fields.forEach((item) => {
            let _value = company[item.kvk_field] || ''
            switch (item.name) {
                case 'Naam':
                    _value = _value.trim()
                    break
                case 'Kvk nummer':
                    _value = _value.substring(0, 8)
                    break
                case 'Plaats':
                    _value = _value.toLowerCase()
                    break
                case 'GMB':
                    if (company['business_name']) {
                        const gmb = company['business_name'].trim() + ' ' + company['city'].trim()
                        _value =
                            'https://www.google.nl/search?q=' + gmb.toLowerCase().replace(/ /g, '+')
                    }
                    break
                default:
                    break
            }
            _values[item.name] = _value
            if (item.required) {
                if (item.name == 'Rubriek') {
                    _validations[item.name] = (val) => typeof val !== 'number'
                } else {
                    _validations[item.name] = (val) => val.trim() === ''
                }
            }
        })
        setValues(_values)
        setValidations(_validations)
        setFormOpen(true)
    }

    const setGmbValues = (gmb) => {
        const components = gmb.address_components || []
        components.map((component) => {
            gmb[component.types[0]] = component.long_name
        })
        fields.forEach((item) => {
            if ((!values[item.name] && gmb[item.gmb_field]) || item.name === 'Name') {
                setValue(item.name, gmb[item.gmb_field])
            }
        })
    }

    const onClickSave = () => {
        if (isValid(true)) {
            setLoading(true)
            call('office/providers/create-new-provider', {
                json: { ...values, country, franchiseStatus },
            }).then((res) => {
                if (franchiseStatus && res.provider && res.provider.provider) {
                    const {
                        service_provider_id: value,
                        business_name: title,
                        country_code,
                    } = res.provider.provider
                    dispatch({
                        type: 'FRANCHISES_ADD',
                        franchises: [{ value, title, country_code }],
                    })
                }

                setResult(res)
                setLoading(false)
            })
        }
    }

    const onFinish = () => {
        setOpen(false)
        setResult(false)
        setFormOpen(false)
        refresh()
    }

    return (
        <div>
            <button onClick={() => setOpen(true)}>
                <Icon>add </Icon>
            </button>

            <Modal open={open} close={() => setOpen(false)} modalInsideStyle={{ zIndex: 300 }}>
                <div style={{ zIndex: 200, minHeight: '75vh' }}>
                    <AutoRow>
                        <Radio
                            value={country}
                            inline
                            onChange={(e) => {
                                setCountry(e.target.value)
                            }}
                            options={[
                                { name: getFlag('nl'), value: 'NL' },
                                { name: getFlag('be'), value: 'BE' },
                                { name: getFlag('de'), value: 'DE' },
                                { name: getFlag('es'), value: 'ES' },
                            ]}
                        />
                        <SelectCheckbox
                            multiple={false}
                            value={franchiseStatus}
                            placeholder="Franchise"
                            onChangeValue={(values) => setFranchiseStatus(parseInt(values[0]))}
                            options={[
                                { value: 0, name: 'No franchise' },
                                { value: 1, name: 'Read-only franchise' },
                                { value: 2, name: 'Full-access franchise' },
                            ]}
                        />
                    </AutoRow>
                    <Divider m />
                    <Row>
                        <Col xs={12}>
                            <SearchKvkCompanyInput country={country} setKvkCompanyFunc={initForm} />
                        </Col>
                    </Row>
                    <Divider s />
                    <Row>
                        <Col x>
                            <Button onClick={initForm}>
                                {formOpen ? 'Empty fields' : 'Open form without KvK'}
                            </Button>
                        </Col>
                    </Row>
                    <Divider l />
                    {formOpen && (
                        <div>
                            {Object.keys(values).map((key) => (
                                <Fragment key={key}>
                                    <Row end style={key == 'Naam' ? { marginTop: '10px' } : {}}>
                                        <Col xs={4} style={{ margin: 'auto' }}>
                                            {key}:
                                        </Col>
                                        <Col xs={8}>
                                            {key == 'Naam' ? (
                                                <GeoAutoComplete
                                                    name={'naam'}
                                                    icon={false}
                                                    state={{
                                                        value: values[key],
                                                        error: false,
                                                    }}
                                                    onChange={(e) => {
                                                        setValue(key, e.target.value)
                                                    }}
                                                    onPlaceChange={(place) => {
                                                        setGmbValues(place)
                                                    }}
                                                    options={{
                                                        types: ['establishment'],
                                                        componentRestrictions: { country: country },
                                                    }}
                                                    msg={'Click for geo search'}
                                                />
                                            ) : key == 'Rubriek' ? (
                                                <SelectCheckbox
                                                    value={values[key]}
                                                    multiple={false}
                                                    onChangeValue={(values) =>
                                                        setValue(key, values[0])
                                                    }
                                                    placeholder="Pick service"
                                                    options={visibleServices.map((key) => {
                                                        return {
                                                            name: services[key].name,
                                                            value: services[key].service_id,
                                                            ...services[key],
                                                        }
                                                    })}
                                                />
                                            ) : (
                                                // <Select
                                                //     placeholder="service"
                                                //     options={visibleServices.map(
                                                //         (key) => services[key]
                                                //     )}
                                                //     optionKeys={{
                                                //         valueKey: 'service_id',
                                                //         msgKey: 'name',
                                                //         nameKey: 'name',
                                                //     }}
                                                //     value={values[key]}
                                                //     onChange={(e) => setValue(key, e.target.value)}
                                                //     error={errors[key]}
                                                // />
                                                <InputField
                                                    value={values[key]}
                                                    onChange={(e) => setValue(key, e.target.value)}
                                                    error={errors[key]}
                                                />
                                            )}
                                        </Col>
                                    </Row>
                                    <Divider m />
                                </Fragment>
                            ))}
                            <Row>
                                <Col>
                                    <Button onClick={onClickSave} disabled={loading}>
                                        {loading ? <LoadingDots /> : 'Save'}
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    )}
                    <Modal open={result} close={() => setResult(false)}>
                        {result.errors && result.errors.msg ? (
                            <Row>
                                <Col>
                                    <span>Error: </span>
                                </Col>
                                <Col>
                                    <span>{result.errors.msg}</span>
                                </Col>
                            </Row>
                        ) : result.provider && result.provider.provider ? (
                            <div>
                                <Row>
                                    <Col>
                                        <h3>Account created.</h3>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col x>
                                        <Button>
                                            <A
                                                to={`/service/providers/provider/${result.provider.provider['service_provider_id']}/profile`}>
                                                Go to provider
                                            </A>
                                        </Button>
                                    </Col>
                                    <Col x>
                                        <Button onClick={onFinish}>Back</Button>
                                    </Col>
                                </Row>
                            </div>
                        ) : (
                            <span>Unknown error.</span>
                        )}
                    </Modal>
                </div>
            </Modal>
        </div>
    )
}

export default AddProForm
