import { SelectCheckbox } from '@advanza/input'
import React, { useState, useEffect } from 'react'
import { call } from '@advanza/api'

const SearchKvkCompanyInput = ({ setKvkCompanyFunc, country = 'NL' }) => {
    const [options, setOptions] = useState([])
    const [optionValue, setOptionValue] = useState('')
    const [prevSearch, setPrevSearch] = useState('')
    const [loading, setLoading] = useState(false)
    const selectOptions = options.map((item) => {
        return {
            name:
                item.business_name +
                ' - ' +
                item.city +
                ' - ' +
                item.kvk_nr +
                (loading ? '...' : ''),
            value: item.business_name + ' - ' + item.city + ' - ' + item.kvk_nr,
            _item: item,
        }
    })
    useEffect(() => {
        const fetchKvk = async () => {
            if (!loading && optionValue.length >= 3 && optionValue !== prevSearch) {
                setLoading(true)
                setPrevSearch(optionValue)
                call(`office/providers/search-kvk-registry/${optionValue}/${country}`).then(
                    (res) => {
                        setOptions(res.list)
                        setLoading(false)
                    }
                )
            }
        }
        fetchKvk()
    }, [loading, optionValue, prevSearch])

    return (
        <div>
            <SelectCheckbox
                placeholder="Find company (only for NL & BE)"
                multiple={false}
                wide
                rememberQuery
                large
                onChangeQuery={(q) => setOptionValue(q)}
                value={optionValue}
                onChangeValue={(values) => {
                    const value = values[0]
                    const item = selectOptions.filter((o) => o.value === value)[0]._item
                    setOptionValue(value)
                    setKvkCompanyFunc(item)
                }}
                options={selectOptions}
            />
        </div>
    )
}

export default SearchKvkCompanyInput
