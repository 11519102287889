import { Radio, SelectSimple } from '@advanza/input'
import Modal from '@advanza/modal'
import { Button, Divider } from '@advanza/ui'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import {
    CANCEL_MODE_COMPLETE,
    CANCEL_MODE_NEVER,
    CANCEL_MODE_SILENT,
    fetchCancelReasons,
    unsubscribe,
} from 'actions/providers'
import AutoRow from 'components/misc/AutoRow'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import { useEffect, useState } from 'react'

type CancelMode = typeof CANCEL_MODE_NEVER | typeof CANCEL_MODE_SILENT | typeof CANCEL_MODE_COMPLETE

type CancelReasons = {
    [reason: number]: string
}

const CANCEL_REASON_OTHER = 10
const CANCEL_REASON_UNSUBSCRIBED_BY_US = 101 // legacy value used in old office unsubscribes

interface Props {
    providerId: number
}

const PremiumCancelModal = NiceModal.create(({ providerId }: Props) => {
    const dispatch = useAppDispatch()
    const cancelReasons: CancelReasons =
        useAppSelector((state) => state.cancellations.extra.cancelReasons) || {}
    const [mode, setMode] = useState<CancelMode>(CANCEL_MODE_COMPLETE)
    const [byUs, setByUs] = useState(1)
    const [reason, setReason] = useState<number | null>(null)
    const [isSaving, setIsSaving] = useState(false)
    const modal = useModal()

    useEffect(() => {
        dispatch(fetchCancelReasons())
    }, [dispatch])

    useEffect(() => {
        setReason(null)
    }, [byUs])

    const onClose = () => {
        modal.resolve()
        modal.hide()
    }

    const unsubscribeWrapper = async () => {
        setIsSaving(true)

        try {
            await dispatch(unsubscribe(providerId, mode, reason))
            onClose()
        } finally {
            setIsSaving(false)
        }
    }

    // @ts-ignore
    return (
        <Modal open={modal.visible} close={onClose} afterExit={() => modal.remove()}>
            <h2>Unsubscribe customer</h2>
            <Divider l />
            <Radio
                placeholder="Select the type of unsubscription"
                legend
                options={[
                    {
                        value: CANCEL_MODE_NEVER,
                        name: 'Never existed unsubscribe (no mail, no registration)',
                    },
                    {
                        value: CANCEL_MODE_SILENT,
                        name: 'Silent unsubscribe (no mail)',
                    },
                    {
                        value: CANCEL_MODE_COMPLETE,
                        name: 'Complete unsubscribe (send mail to customer)',
                    },
                ]}
                value={mode}
                onChange={(e) => setMode(e.target.value as CancelMode)}
            />
            {mode !== CANCEL_MODE_NEVER && (
                <>
                    <Divider m />
                    <Radio
                        placeholder="Select who initiated the unsubscription"
                        legend
                        options={[
                            {
                                value: 0,
                                name: 'Customer has asked us to unsubscribe',
                            },
                            {
                                value: 1,
                                name: 'Unsubscription is initiated by us',
                            },
                        ]}
                        value={byUs}
                        onChange={(e) => setByUs(e.target.value as number)}
                    />
                    <Divider m />
                    {/*@ts-ignore*/}
                    <SelectSimple
                        placeholder="Select an unsubscribe reason"
                        legend
                        emptyOption=" "
                        // @ts-ignore
                        options={Object.entries(cancelReasons)
                            .map(([reason, reasonText]) => ({
                                value: parseInt(reason, 10),
                                name: reasonText,
                            }))
                            .filter(({ value }) =>
                                byUs
                                    ? CANCEL_REASON_UNSUBSCRIBED_BY_US < value
                                    : value < CANCEL_REASON_UNSUBSCRIBED_BY_US
                            )
                            .sort(
                                ({ value: valueA }, { value: valueB }) =>
                                    Number(valueA === CANCEL_REASON_OTHER) -
                                    Number(valueB === CANCEL_REASON_OTHER)
                            )}
                        // @ts-ignore
                        value={reason}
                        onChange={(e: any) => setReason(e.target.value)}
                    />
                </>
            )}
            <Divider l />
            <AutoRow between>
                <Button appearance="outlined_primary" disabled={isSaving} onClick={() => onClose()}>
                    Cancel
                </Button>
                <Button
                    appearance="primary"
                    disabled={isSaving || (mode !== CANCEL_MODE_NEVER && !reason)}
                    onClick={() => unsubscribeWrapper()}>
                    Unsubscribe customer
                </Button>
            </AutoRow>
        </Modal>
    )
})

export default PremiumCancelModal
