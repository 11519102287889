import { PreIcon } from '@advanza/ui'
import React, { Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import style from './../inputField.module.css'

class InputField extends React.Component {
    constructor(props) {
        super(props)
        this.onFocus = this.onFocus.bind(this)
        this.onBlur = this.onBlur.bind(this)
        this.doFocus = this.doFocus.bind(this)
        this.state = { focused: false, value: '' }
        this.hasAutofocused = 0
    }

    componentDidMount() {
        this.isTouchDevice =
            'ontouchstart' in window ||
            navigator.maxTouchPoints > 0 ||
            navigator.msMaxTouchPoints > 0
    }

    onFocus(e) {
        const { readOnly } = this.props

        if (!readOnly) {
            this.setState({ focused: true })
        } else {
            document.activeElement.blur()
        }
    }

    onBlur(e) {
        this.setState({ focused: false })
        this.props.onBlur && this.props.onBlur(e)
    }

    inputPropWhiteList = [
        'id',
        'onFocus',
        'onClick',
        'disabled',
        'readOnly',
        'onFocus',
        'onKeyDown',
        'max',
        'min',
        'step',
        'rows',
        'autoComplete',
        'inputMode',
        'onKeyPress',
        'maxLength',
        'title',
    ]

    doFocus() {
        this.ref && this.ref.focus()
    }

    render() {
        const {
            t,
            hidePlaceholder,
            value: valueProp = '',
            error,
            blueBorderValid,
            compact,
            defaultErrorMsg,
            placeholder,
            afterPlaceholder,
            inputPlaceholder,
            placeholderLarge,
            textArea,
            onChange,
            before,
            name,
            type = 'text',
            readOnly = false,
            after,
            hideAfterIconStyle = false,
            children,
            style: inlineStyle,
            autoFocus,
            inputStyle,
            classNames = '',
            preIcon = false,
            legend = false,
            disableValueFocus = false,
            newStyle = false,
            controlled = true,
            ...rest
        } = this.props
        const value = (controlled ? valueProp : this.state.value) ?? ''
        const hasValue = value === 0 || (value && value !== '')
        const hasActiveFocus = this.state.focused
        const hasFocus = (hasActiveFocus && !rest.disabled) || hasValue
        //React.isValidElement is in the legacy API. Below is a JS alternative
        const isReactElement = (element) => {
            return element?.$$typeof === Symbol.for('react.element')
        }
        const errorMsg =
            typeof error === 'string' || isReactElement(error)
                ? error
                : defaultErrorMsg !== undefined
                ? defaultErrorMsg || ''
                : t('form_default_error_text')

        const props = {
            'data-field-has-error': error ? true : undefined,
            onFocus: this.onFocus,
            onBlur: this.onBlur,
            onChange: (e) => {
                onChange && onChange(e)
                !controlled && this.setState({ value: e.target.value })
            },
            placeholder: inputPlaceholder,
            name,
            value,
            type,
            style: inputStyle,
            readOnly,
            className: style.input,
            ref: (el) => {
                if (autoFocus && this.hasAutofocused < 5) {
                    el && document.activeElement !== el && el.focus()
                    this.hasAutofocused++
                }
                this.ref = el
                rest.ref && rest.ref(el)
                rest.registerRef && rest.registerRef(el)
            },
        }
        Object.keys(rest).forEach((key) => {
            if (this.inputPropWhiteList.indexOf(key) !== -1) {
                props[key] = rest[key]
            }
        })
        const className = [
            style.root,
            hasActiveFocus ? style.activeFocus : '',
            hasValue && !(legend || disableValueFocus) ? style.valueFocus : '',
            hasFocus ? style.focused : '',
            hidePlaceholder ? style.hidePlaceholder : '',
            value !== '' ? style.notEmpty : '',
            error ? style.hasError : '',
            blueBorderValid ? style.validBorder : '',
            compact ? style.compact : '',
            legend ? style.hasLegend : '',
            newStyle ? style.newStyle : '',
        ].join(' ')
        return (
            <div className={[className, classNames].join(' ')} style={inlineStyle}>
                {legend && (
                    <div className={style.legend}>
                        {placeholder}
                        {afterPlaceholder}
                    </div>
                )}
                <label className={style.label}>
                    {before && <div className={style.before}>{before}</div>}
                    {React.createElement(textArea ? 'textarea' : 'input', props)}
                    {!legend && (
                        <div className={style.placeholder}>
                            {hasFocus ? placeholder : placeholderLarge || placeholder}
                        </div>
                    )}
                    {children}
                    {after && (
                        <div
                            className={[
                                style.after,
                                hideAfterIconStyle ? '' : style.withIconStyle,
                            ].join(' ')}>
                            {after}
                        </div>
                    )}
                </label>
                {error && preIcon ? (
                    <div className={style.errorPreIcon}>
                        <PreIcon name="error" fontSize={18}>
                            {errorMsg}
                        </PreIcon>
                    </div>
                ) : error ? (
                    <div className={style.error}>{errorMsg}</div>
                ) : (
                    <Fragment />
                )}
            </div>
        )
    }
}

InputField.propTypes = {}

export default withTranslation('shared')(InputField)
