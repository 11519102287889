import { Row } from '@advanza/grid'
import { WhatsappSvg } from '@advanza/misc/WhatsappSvg'
import { HoverText, Icon } from '@advanza/ui'
import ManualConfirmButton from 'components/matches/ManualConfirmButton'
import ManualReclaimButton from 'components/matches/ManualReclaimButton'
import PartialMatchButton from 'components/matches/PartialMatchButton'
import TableList from 'components/TableList'
import Balloon from 'components/ui/Balloon'
import FormattedTime from 'components/ui/FormattedTime'
import { formatDistanceStrictWrap, utcToLocal } from 'date'
import { useAppSelector } from 'hooks/hooks'
import { useList } from 'hooks/listHooks'
// @ts-ignore
import { FormattedNumber } from 'react-intl'
import { Link } from 'react-router-dom'
import matchesSchema from 'schemes/matchesSchema'
import style from './matchList.module.css'

interface Props {
    selectable?: boolean
    filterId: string
    defaultOptions: any
    showCols: string[]
    hideExport?: boolean
}

const MatchList = ({
    selectable,
    filterId,
    defaultOptions = {},
    showCols,
    hideExport = true,
}: Props) => {
    const services = useAppSelector((state) => state.services.entities.services)
    const { entities, ...rest } = useList(
        filterId,
        {
            url: 'office/matches/get-list',
            schema: matchesSchema,
            reducerKey: 'matches',
        },
        defaultOptions
    )

    function getUrl(id: number) {
        return `/service/matches/match/${id}`
    }

    function renderColumns(id: number) {
        const { matches } = entities
        const match = matches[id]
        const service = (services && services[match.serviceId]) || {}
        const EventsMap = {
            confirmed: {
                color: 'blue',
                content: 'confirmed ' + formatDistanceStrictWrap(match.confirmed, match.created),
            },
            is_clicked: {
                color: 'greenBright',
                content:
                    'pro match mail click ' +
                    formatDistanceStrictWrap(match.is_clicked, match.created),
            },
            is_visited: {
                color: 'purple',
                content: 'user visit ' + formatDistanceStrictWrap(match.is_visited, match.created),
            },
            is_read: {
                color: 'green',
                content: 'pro visit ' + formatDistanceStrictWrap(match.is_read, match.created),
            },
            phone_view: {
                color: 'yellow',
                content: 'phone view ' + formatDistanceStrictWrap(match.phone_view, match.created),
            },
            pro_clicked_whatsapp: {
                color: 'greenWhatsapp',
                content:
                    'pro clicked whatsapp ' +
                    formatDistanceStrictWrap(match.pro_clicked_whatsapp, match.created),
                icon: <WhatsappSvg width={10} height={10} style={{ color: 'white' }} />,
            },
            hired: {
                color: 'orange',
                content: 'hired ' + formatDistanceStrictWrap(match.hired, match.created),
            },
            finished: {
                color: 'yellow',
                content: 'marked finished ' + formatDistanceStrictWrap(match.hired, match.created),
            },
            reclaimed: {
                color: 'magenta',
                content: 'reclaimed ' + formatDistanceStrictWrap(match.reclaimed, match.created),
            },
            rejected: {
                color: 'black',
                content: 'rejected ' + formatDistanceStrictWrap(match.rejected, match.created),
            },
            from_dashboard: {
                color: 'black',
                content: 'Extra match added on ' + utcToLocal(match.created),
            },
            noInterestDate: {
                color: 'red',
                content: `declined ${formatDistanceStrictWrap(
                    match.noInterestDate,
                    match.created
                )} (${match.noInterestReason})`,
            },
        }
        const fields = Object.keys(match)
            //@ts-ignore
            .map((key) => EventsMap[key] && match[key] && { ...EventsMap[key], date: match[key] })
            .filter(Boolean)

        fields.sort((a, b) => {
            a = new Date(a.date)
            b = new Date(b.date)
            return a > b ? 1 : a < b ? -1 : 0
        })

        const checkpointStripes = (
            <Row style={{ flexWrap: 'noWrap', margin: 0 }}>
                {fields.map((checkpoint) => {
                    return (
                        <Balloon key={checkpoint.content} content={checkpoint.content}>
                            <div
                                style={{
                                    width: '1em',
                                    height: '1em',
                                    borderRadius: '50%',
                                    marginRight: 1,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                                className={style[checkpoint.color]}>
                                {checkpoint.icon}
                            </div>
                        </Balloon>
                    )
                })}
            </Row>
        )

        return [
            <small className="text">
                <Link to={getUrl(id)}>#{id}</Link>
            </small>,
            <div>
                <Link
                    title="to pro"
                    className="plain"
                    to={`/service/providers/provider/${match.service_provider_id}`}>
                    {/*@ts-ignore*/}
                    {match.rejected ? <strike>{match.business_name}</strike> : match.business_name}
                    {match.isSigned && (
                        <img
                            style={{
                                verticalAlign: 'middle',
                                paddingLeft: 3,
                                display: 'inline-block',
                            }}
                            src="https://static.trustoo.nl/icon/premium.svg"
                            width="16"
                        />
                    )}
                    {match.isFreeTrialLead && (
                        <Balloon content="Free trial match">
                            <Icon
                                name="explosion"
                                green
                                fontSize={16}
                                style={{
                                    verticalAlign: 'text-bottom',
                                    paddingLeft: 3,
                                }}
                            />
                        </Balloon>
                    )}
                </Link>
            </div>,
            service.name,
            match.first_choice ? (
                <Balloon content="First choice">
                    <Icon small>verified_user</Icon>
                </Balloon>
            ) : (
                ' '
            ),
            match.fromCandidateMatch ? (
                <Balloon content="From candidate match">
                    <Icon small name="switch_access_shortcut" />
                </Balloon>
            ) : match.from_dashboard ? (
                <Balloon content="Extra match from dashboard">
                    <Icon small>person_add</Icon>
                </Balloon>
            ) : (
                ''
            ),
            match.from_marketplace ? <Icon name="storefront" fontSize={16} /> : '',
            <div>
                {match.chargeAmount && (
                    <b>
                        <small>
                            {match.chargeAmount == 0 ? (
                                /*@ts-ignore*/
                                <strike>
                                    <FormattedNumber
                                        style="currency"
                                        currency="eur"
                                        value={match.chargeAmountAbs}
                                    />
                                    {/*@ts-ignore*/}
                                </strike>
                            ) : (
                                <FormattedNumber
                                    style="currency"
                                    currency="eur"
                                    value={match.chargeAmount}
                                />
                            )}
                            {match.chargeDescriptions.includes('booster surcharge') && (
                                <Icon fontSize={19} name="insights" title="Lead booster" />
                            )}
                        </small>
                    </b>
                )}
            </div>,
            <ManualConfirmButton match={match} />,
            <div>
                <Link
                    title="to request"
                    className="plain"
                    to={`/service/requests/request/${match.request_id}`}>
                    {match.userName || match.userEmail}
                </Link>
            </div>,
            checkpointStripes,
            <span className="score fsize lg">{match.nrMessages}</span>,
            match.is_partial_match && (
                <HoverText trigger={<PartialMatchButton matchId={match.match_id} />}>
                    Is partial match
                </HoverText>
            ),
            <small className="c-grey>">
                {/*@ts-ignore*/}
                <FormattedTime date={match.created} />
            </small>,
            <ManualReclaimButton lead={match} id={match.match_id} leadType="matches" />,
        ]
    }

    function renderRow(id: number) {
        return {
            url: getUrl(id),
            cols: renderColumns(id),
            id,
        }
    }

    const definitions = {
        id: { name: '#', preventRedirect: true },
        name: { name: 'pro', preventRedirect: true },
        service: { name: 'service' },
        firstChoice: { name: '' },
        from_dashboard: { name: '' },
        from_marketplace: { name: '' },
        charge: { name: 'charge' },
        confirm: { name: 'inv', preventRedirect: true },
        userName: { name: 'user' },
        stripes: { name: ' ' },
        nrMsgs: { name: 'msg' },
        partialMatch: { name: 'p', preventRedirect: true },
        created: { name: 'Time' },
        reclaim: { name: 'rec', preventRedirect: true },
    }
    return (
        <TableList
            {...rest}
            selectable={selectable}
            renderRow={renderRow}
            definitions={definitions}
            showCols={showCols}
            addListFeatures
            hideExport={hideExport}
            hideOrder
        />
    )
}

export default MatchList
